body, html {
  height: 100%;
  text-transform: uppercase;
  overflow-y: hidden;
  max-width: 100% !important;
}



.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.height-top-part {
  height: 70%;
}

.height-top-part-inner{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.height-bottem-part {
  height: 30%;
}

.vertical-centre {
  display: flex;
  align-items: center;

  text-align: center;
}

.centre-centre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn {
  border-radius: 30vw !important;
  font-family: Lato,Helvetica,Arial,sans-serif;
  letter-spacing: 0.1vw;
  font-size: calc(16px + .3vw)  !important;
  text-transform: uppercase;
  color: #f7c84c !important;
  border: 0.3vw solid #ffffff !important;
  font-weight: 700 !important;
}

.btn:hover {
  color: #ffffff !important;
  border: 0.3vw solid #ffffff !important;
  transition: color 1s ease-in-and-out;
}

.btn:active {
  color: #ffffff;
  background-color: #f3cd50;
  border: 0.3vw  solid #f3cd50;
}

.context-text {
  color: #f7c84c;
  font-weight: 700 !important;
  font-size: calc(16px + 1.2vw);
}

#equv-text{
  font-weight: bold;
  font-size: calc(16px + 1vw);
  color: White;
}
#source{
  font-weight: bold;
  font-size: calc(10px + 0.5vw);
  color: White;
}

@media only screen and (max-width: 992px) {
  .choices {
    display: flex;
  }
  .height-top-part-inner{
    height: 50%;
  }
}